import React from "react";
import SubmissionForm from "../../components/FormInstansi";

const Instansi = () => {
  return (
    <React.Fragment>
        <SubmissionForm/>
    </React.Fragment>
  );
};

export default Instansi;
